import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Tabs,
  Tab,
  Grid2 as Grid,
  Paper,
  List,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { validateEmail, validateRequired } from 'src/common/validations';
import DynamicForm from 'src/components/ReduxForm/DynamicForm/DynamicForm';

import GuestExistingSelector from './GuestExistingSelector';
import GuestListItem from './GuestListItem';
import {
  COMARKETING_FORM_NAME,
  allGuestFormFields,
  guestFormFields
} from './constants';

const StyledIcon = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 0.5),
  aspectRatio: '1',
  height: '100%'
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[100],
  color: theme.palette.grey[700],
  '& .MuiAlert-icon': {
    color: theme.palette.grey[700]
  }
}));

const formInputsExisting = [
  {
    name: guestFormFields.message,
    displayName: 'Invite Message (optional)',
    displayMethodId: INPUT_TYPES.MULTI_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    displayParameters: {
      inputData: {
        blockEmojiPicker: true,
        columnWidth: 4,
        rows: 4
      }
    }
  }
];

const formInputs = [
  {
    name: guestFormFields.name,
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    displayParameters: {
      inputData: {
        blockEmojiPicker: true,
        columnWidth: 4
      }
    }
  },
  {
    name: guestFormFields.email,
    displayName: 'E-mail Address',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateEmail()],
    isRequired: true,
    displayParameters: {
      inputData: {
        blockEmojiPicker: true,
        columnWidth: 4
      }
    }
  },
  ...formInputsExisting
];

const GuestInviteModal = () => {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const { setValue, unregister, trigger, getValues, formState } =
    useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const name = useWatch({
    name: `${COMARKETING_FORM_NAME}.${guestFormFields.name}`
  });

  const email = useWatch({
    name: `${COMARKETING_FORM_NAME}.${guestFormFields.email}`
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const clearFormFields = (fields: string[], unregisterFields = false) => {
    fields.forEach(field => {
      if (unregisterFields) {
        unregister(`${COMARKETING_FORM_NAME}.${field}`);
      } else {
        setValue(`${COMARKETING_FORM_NAME}.${field}`, undefined);
      }
    });
  };

  const triggerFormFields = async () => {
    const fields = getValues(COMARKETING_FORM_NAME);
    // touch all fields
    if (fields && typeof fields === 'object') {
      Object.keys(fields).forEach(fieldKey => {
        setValue(`${COMARKETING_FORM_NAME}.${fieldKey}`, fields[fieldKey], {
          shouldTouch: true,
          shouldValidate: true,
          shouldDirty: true
        });
      });
    }
    await trigger(Object.keys(fields));
  };

  const handleClose = () => {
    // Reset all form fields in the coMarketing section
    clearFormFields(allGuestFormFields, true);
    // Reset the selected tab
    setSelectedTab(0);
    setOpen(false);
  };

  const handleCloseModal = (
    event: React.KeyboardEvent<HTMLDivElement>,
    reason: string
  ) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      return;
    }
    handleClose();
  };

  const handleSave = () => {
    // validate form fields
    triggerFormFields()
      .then(() => {
        const coMarketingErrors = Object.keys(
          formState.errors[COMARKETING_FORM_NAME] || {}
        );

        if (coMarketingErrors.length === 0) {
          setOpen(false);
        } else {
          enqueueSnackbar(t('guest:invite.validationError'), {
            variant: 'error'
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t('guest:invite.error'), {
          variant: 'error'
        });
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    clearFormFields([
      guestFormFields.name,
      guestFormFields.email,
      guestFormFields.selectedGuest,
      guestFormFields.hostGuestAssociationId
    ]);

    setValue(
      `${COMARKETING_FORM_NAME}.${guestFormFields.inviteExisting}`,
      newValue === 1
    );
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', width: '100%', gap: 2, alignItems: 'center' }}
      >
        {name && email && !open ? (
          <>
            <Box sx={{ flex: '1 0 50%' }}>
              <List
                component={Paper}
                sx={{
                  mb: 2,
                  mt: 2
                }}
              >
                <GuestListItem
                  name={name}
                  email={email}
                  onEdit={handleOpen}
                  onClose={handleClose}
                />
              </List>
            </Box>

            <Box sx={{ flex: '1 0 50%' }}>
              <StyledAlert
                severity="info"
                icon={<InfoOutlinedIcon fontSize="small" />}
              >
                {t('guest:spend.coMarketingAlertAccepted')}
              </StyledAlert>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{ flex: '1 0 50%', display: 'flex', alignItems: 'center' }}
            >
              <Button
                onClick={handleOpen}
                startIcon={<AddIcon />}
                endIcon={
                  <StyledIcon>
                    <HandshakeIcon fontSize="small" />
                  </StyledIcon>
                }
              >
                {t('guest:invite.title')}
              </Button>
            </Box>
            <Box sx={{ flex: '1 0 50%' }}>
              <StyledAlert
                severity="info"
                icon={<InfoOutlinedIcon fontSize="small" />}
              >
                {t('guest:spend.coMarketingAlert')}
              </StyledAlert>
            </Box>
          </>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
      >
        <DialogTitle
          sx={{
            paddingBottom: 2,
            borderBottom: theme => `1px solid ${theme.palette.divider}`
          }}
        >
          {t('guest:invite.title')}
          <Typography variant="subtitle1" color="text.secondary">
            {t('guest:invite.subtitle')}
          </Typography>
        </DialogTitle>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="co-marketing invite options"
          >
            <Tab
              icon={<PersonAddIcon />}
              iconPosition="start"
              label={t('guest:invite.tabs.new')}
            />
            <Tab
              icon={<ContactPageIcon />}
              iconPosition="start"
              label={t('guest:invite.tabs.existing')}
            />
          </Tabs>
        </Box>
        <DialogContent>
          {selectedTab === 0 ? (
            <Box>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <DynamicForm
                    inputs={formInputs}
                    formSectionName={COMARKETING_FORM_NAME}
                    isHookForm
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box>
                    <img
                      src="https://ev-prod.s3.amazonaws.com/public/comarketing/comarketing-info.png"
                      alt="Collaborative Marketing Platform"
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        display: 'block'
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <GuestExistingSelector />
                  <DynamicForm
                    inputs={formInputsExisting}
                    formSectionName={COMARKETING_FORM_NAME}
                    isHookForm
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box>
                    <img
                      src="https://ev-prod.s3.amazonaws.com/public/comarketing/comarketing-info.png"
                      alt="Collaborative Marketing Platform"
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        objectFit: 'contain',
                        display: 'block'
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GuestInviteModal;
