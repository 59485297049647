import { t } from 'i18next';
import { Box, Typography } from '@mui/material';
import HandshakeIcon from '@mui/icons-material/Handshake';

import { styled } from '@mui/material/styles';

const StyledIcon = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 0.5),
  aspectRatio: '1',
  height: '100%'
}));

const StyledBadge = styled(Box)(() => ({
  display: 'inline-flex',
  alignContent: 'stretch'
}));

const CoMarketingBadge = () => {
  return (
    <StyledBadge>
      <StyledIcon>
        <HandshakeIcon />
      </StyledIcon>
      <Box sx={{ pl: 1 }}>
        <Typography
          component="div"
          variant="body2"
          sx={{ fontSize: '.7rem', lineHeight: 1.2 }}
        >
          {t('guest:badge.available')}
        </Typography>
        <Typography
          component="div"
          variant="h6"
          sx={{ fontSize: '.9rem', lineHeight: 1.2 }}
        >
          {t('guest:badge.coMarketing')}
        </Typography>
      </Box>
    </StyledBadge>
  );
};

export default CoMarketingBadge;
