import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Box
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

interface GuestListItemProps {
  name: string;
  email: string;
  onEdit: () => void;
  onClose: () => void;
}

const GuestListItem = ({
  name,
  email,
  onEdit,
  onClose
}: GuestListItemProps) => (
  <ListItem
    secondaryAction={
      <Box sx={{ display: 'flex' }}>
        <IconButton onClick={onEdit} size="small" sx={{ mr: 1 }}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
    }
  >
    <ListItemAvatar>
      <Avatar>
        <PersonIcon />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={name}
      secondary={email}
      primaryTypographyProps={{ variant: 'subtitle1' }}
      secondaryTypographyProps={{ color: 'text.secondary' }}
    />
  </ListItem>
);

export default GuestListItem;
