import { useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useWatch } from 'react-hook-form';

import { t } from 'i18next';

import { Alert, Box, Grid, Divider } from '@mui/material';

import SpendSelector, { useMinSpendState } from 'src/components/SpendSelector';
import CustomFormMessage from 'src/components/CustomFormMessage';
import Checkout from 'src/components/Checkout';
import { useAppSettings } from 'src/AppSettings';
import { FormSection } from 'src/components/ReduxForm';
import ScheduleSelector, {
  PaymentTypeSelector
} from 'src/components/ScheduleSelector';
import { getOfferOptions } from 'src/components/ScheduleSelector/utils';
import CreditCardProvider from 'src/components/Checkout/CreditCardProvider';
import useProgram from 'src/pages/Program/utils/useProgram';
import { CANCELLATION_TYPES } from 'src/pages/ProgramPerformance/Constants';
import GuestInviteModal from 'src/pages/Program/Guest/GuestInviteModal';

import { PROGRAM_FORM_SECTION_SPEND_NAME, scheduleTypes } from '../Constants';

const pageText = () => {
  return {
    paymentTypeSectionTitle: t('programCreate:spend.paymentTypeSectionTitle'),
    paymentTypeSectionDescription: t(
      'programCreate:spend.paymentTypeSectionDescription'
    ),
    paymentTypeSectionDescriptionCoMarketing: t(
      'programCreate:spend.paymentTypeSectionDescriptionCoMarketing'
    )
  };
};

const getScheduleSectionDescription = ({ isAutomated, isSubscription }) => {
  if (isAutomated) {
    return t('programCreate:spend.offerTypeDescriptionAutomation');
  }

  if (isSubscription) {
    return t('programCreate:spend.offerTypeDescriptionSubscription');
  }
  return t('programCreate:spend.offerTypeDescription');
};

export const getBudgetSectionTitle = ({ isSubscription }) => {
  if (isSubscription) {
    return t('programCreate:spend.budgetSectionTitleSubscription');
  }
  return t('programCreate:spend.budgetSectionTitle');
};

const getBudgetSectionDescription = ({
  isAutomated,
  isSubscription,
  cancellationType
}) => {
  if (isAutomated) {
    if (isSubscription) {
      t('programCreate:spend.budgetSectionDescriptionAutomationSubscription');
    }
    return t('programCreate:spend.budgetSectionDescriptionAutomation');
  }

  if (isSubscription) {
    if (cancellationType === CANCELLATION_TYPES.deferred) {
      return t(
        'programCreate:spend.budgetSectionDescriptionSubscriptionDeferred'
      );
    }
    if (cancellationType === CANCELLATION_TYPES.immediate) {
      return t(
        'programCreate:spend.budgetSectionDescriptionSubscriptionImmediate'
      );
    }
    return t('programCreate:spend.budgetSectionDescriptionSubscription');
  }

  return t('programCreate:spend.budgetSectionDescription');
};

const ProgramStepSpend = props => {
  const {
    isAutomated,
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    skipStep,
    setSkipStep,
    type,
    submitForm,
    handleNext,
    lockSubmit,
    showValidationErrors,
    offersChanged
  } = props;

  const { isDraft, isCoMarketing } = useProgram();
  const isSubscription =
    useWatch({ name: 'spendStep.scheduleType', defaultValue: '' }) ===
    scheduleTypes.subscription.value;
  const offerId = useWatch({ name: 'spendStep.offerId', defaultValue: '' });

  const appSettings = useAppSettings();

  const text = useMemo(() => pageText(), []);

  const {
    minSpendLoading,
    setMinSpendLoading,
    hasMinSpendError,
    setHasMinSpendError
  } = useMinSpendState();

  const paymentPlans = selectedBlueprint?.paymentPlans;

  // eslint-disable-next-line prefer-destructuring
  const stripeKey = appSettings.app.general.stripeKey;

  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  const customMessage = selectedBlueprint?.messaging?.spendMessage;
  const offers = isAutomated
    ? paymentPlans?.offers?.filter(offer => offer.isSupervisable)
    : paymentPlans?.offers;

  const cancellationType = selectedBlueprint?.offers?.find(
    offer => offer.id === offerId
  )?.cancellationType;

  return (
    <Grid data-cy="program-form-spend-step" container gap={2}>
      {offersChanged && !isDraft && (
        <Grid item xs={12}>
          <Alert severity="warning">
            {t('programCreate:spend.offersChanged')}
          </Alert>
        </Grid>
      )}
      <Grid
        sx={getOfferOptions(offers)?.length < 2 ? { display: 'none' } : {}}
        item
        xs={12}
      >
        <FormSection
          title={text.paymentTypeSectionTitle}
          description={
            isCoMarketing
              ? text.paymentTypeSectionDescriptionCoMarketing
              : text.paymentTypeSectionDescription
          }
        >
          <PaymentTypeSelector offers={offers} />
          {isCoMarketing && (
            <>
              <Divider sx={{ mb: 2 }} />
              <GuestInviteModal />
            </>
          )}
        </FormSection>
      </Grid>
      {!(isAutomated && isSubscription) && (
        <Grid item xs={12}>
          <FormSection
            title={
              isSubscription
                ? t('programCreate:spend.offerTypeTitleSubscription')
                : t('programCreate:spend.offerTypeTitle')
            }
            description={getScheduleSectionDescription({
              isAutomated,
              isSubscription
            })}
          >
            <Box sx={{ pb: 1.5 }}>
              <ScheduleSelector
                selectedBlueprint={selectedBlueprint}
                isAutomated={isAutomated}
                formSectionName={PROGRAM_FORM_SECTION_SPEND_NAME}
              />
            </Box>
          </FormSection>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormSection
          title={getBudgetSectionTitle({
            isSubscription
          })}
          description={getBudgetSectionDescription({
            isAutomated,
            isSubscription,
            cancellationType
          })}
        >
          <Box
            sx={{
              pb: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'flex-start'
            }}
          >
            <SpendSelector
              architecture={architecture}
              paymentPlans={paymentPlans}
              selectedBlueprint={selectedBlueprint}
              isAutomated={isAutomated}
              setMinSpendLoading={setMinSpendLoading}
              setHasMinSpendError={setHasMinSpendError}
              formSectionName={PROGRAM_FORM_SECTION_SPEND_NAME}
              hasMinSpendError={hasMinSpendError}
              offerId={offerId}
            />
            {customMessage && (
              <Box sx={{ pb: 0.5 }}>
                <CustomFormMessage message={customMessage} />
              </Box>
            )}
          </Box>
        </FormSection>
      </Grid>
      <Grid item xs={12}>
        <Elements stripe={stripePromise}>
          <CreditCardProvider>
            <Checkout
              architecture={architecture}
              selectedBusinessObjects={selectedBusinessObjects}
              selectedBlueprint={selectedBlueprint}
              isAutomated={isAutomated}
              skipStep={skipStep}
              setSkipStep={setSkipStep}
              minSpendLoading={minSpendLoading}
              hasMinSpendError={hasMinSpendError}
              handleNext={handleNext}
              lockSubmit={lockSubmit}
              showValidationErrors={showValidationErrors}
              type={type}
              submitForm={submitForm}
            />
          </CreditCardProvider>
        </Elements>
      </Grid>
    </Grid>
  );
};

export default ProgramStepSpend;
