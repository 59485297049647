import React, {
  createContext,
  ReactNode,
  useState,
  createRef,
  RefObject
} from 'react';
import { isNil, uniq, find, isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { t } from 'i18next';
import { dayjs } from 'src/common/dates';

import Instrumentation from 'src/instrumentation';
import {
  checkBlueprintHasFacebook,
  getOneOfEachChannelKeys,
  contentSelectable,
  hasCatalog,
  getCreativeType,
  getDynamicFieldFriendlyNamesByFieldName,
  getDynamicFieldDisplayMethodByFieldName
} from 'src/common/blueprints';
import { contentColumnsFromArchitecture } from 'src/common/dynamicUserInputs';
import { adCreativeTypes } from 'src/common/adChannels';

import ProgramConfigure from 'src/pages/Program/ProgramSteps/ProgramStepConfigure/ProgramStepConfigure';
import ProgramPublish from 'src/pages/Program/ProgramSteps/ProgramStepSpend';
import MultiLocationConfigure from 'src/pages/Program/ProgramSteps/MultiLocationConfigure';
import MultiLocationPublish from 'src/pages/Program/ProgramSteps/MultiLocationPublish';
import {
  programActions,
  ProgramActionType,
  PROGRAM_FORM_NAME
} from 'src/pages/Program/Constants';

import {
  guestFormInitialValues,
  COMARKETING_FORM_NAME
} from 'src/pages/Program/Guest/constants';

import {
  convertFilterJSONtoFiltersArray,
  ContentSetFieldMetadata
} from 'src/components/AutomatedProgramFilter/helpers';
import { getDeactivationDateFromTheme } from 'src/common/deactivation';
import { facebookPageTypes } from 'src/common/FacebookUtil';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { useAppSettings } from 'src/AppSettings';
import { useFeatures } from 'src/components/Feature/Feature';
import Loading from 'src/components/Loading';
import { GetAllLocationsQuery } from 'src/generated/gql/graphql';
import useAiChat, { UseAiChatResponse } from 'src/components/AiChat/useAiChat';
import { aiChatTypes } from 'src/components/AiChat/constants';

import useSubmitHandlerByType from './submitHandlers/useSubmitHandlerByType';

import {
  getFacebookPageSelections as getFacebookPageSelectionsQuery,
  getOrgDefaultFacebookPageGroup as getOrgDefaultFacebookPageGroupQuery,
  getOrderToCloneProgram,
  getParentProgramToClone,
  getProgramDrafts,
  DRAFT_MODELS
} from '../queries';

import {
  getInitialDynamicUserInputs,
  getInitialConfigureStepValues,
  getInitialSpendStepValues,
  getInitialLocationIds,
  getInitialLocationOverrideById
} from './initialValues';
import { haveOffersChanged } from './offers';
import {
  getPreselectedFilters,
  getPreselectedPromoCodes
} from './preselectedItems';
import { getDefaultFacebookPage } from './facebook';
import { getAllLocations } from './queries';
import { getDraftOverrrides } from './initialValues/locationIds';

type StepsType = {
  component: ReactNode;
  label: string;
  error: boolean;
};

export type TrackingData = {
  architectureId: string;
  productId: string;
  channel: string;
};

type ProvidedProps = {
  type: ProgramActionType;
  isEditing?: boolean;
  blueprints: any[];
  selectedBlueprint: any | undefined;
  openBlueprintsModal: () => void;
  handleSelectBlueprint: (productId: any, architectureId: any) => void;
  automation?: any;
};

type ContextType = {
  type: ProgramActionType;
  isEditing: boolean;
  isMultiLocation: boolean;
  isDraft: boolean;
  isCoMarketing?: boolean;
  blueprints: any[];
  selectedBlueprint: any | undefined;
  openBlueprintsModal: () => void;
  handleSelectBlueprint: (productId: any, architectureId: any) => void;
  automation?: any;
  catalogCollapseKey?: string;
  catalogId?: string;
  contentName: string;
  initialValues: any;
  isAutomated: boolean;
  isAutomatedEdit: boolean;
  offersChanged: boolean;
  facebook: any;
  blueprintHasFacebook: boolean;
  hasFacebookPage: boolean;
  showFacebookField: boolean;
  preselectedBusinessObjectIds: string[];
  hasCatalog: boolean;
  isContentSelectable: boolean;
  blueprintChannels: string[];
  dynamicFieldFriendlyNamesByFieldName: { [key: string]: string };
  dynamicFieldDisplayMethodByFieldName: { [key: string]: string };
  contentColumns: any;
  showContentSelector: boolean;
  formName: string;
  programStepper: {
    currentStep: number;
    steps: StepsType[];
    stepRefs: RefObject<HTMLDivElement>[];
    selectExactStep: (step: number, trackingData?: Record<string, any>) => void;
  };
  creativeValidationErrors: Record<string, any> | null;
  setCreativeValidationErrors: (value: Record<string, any> | null) => void;
  promoCodeError: boolean | string | null;
  setPromoCodeError: (value: boolean | string | null) => void;
  selectedLocation?: string;
  setSelectedLocation: (value: string | undefined) => void;
  allMlpLocations: {
    loading: boolean;
    data?: GetAllLocationsQuery;
    error?: any;
  };
  handleProgramSubmit: (data: any, programProps: any) => Promise<any>;

  previewDrawerOpen: boolean;
  setIsPreviewDrawerOpen: (value: boolean) => void;
  togglePreviewDrawer: () => void;
  trackingData: TrackingData;
  currentDraftId: string | undefined;
  setCurrentDraftId: (value: string | undefined) => void;
  aiChatContext: UseAiChatResponse;
  disableNavigationBlocker: boolean;
  setDisableNavigationBlocker: (value: boolean) => void;
};

type ProgramProviderProps = ProvidedProps & {
  children: ReactNode;
};

export const ProgramContext = createContext<ContextType | undefined>(undefined);

const pageText = () => ({
  programCondensedStepConfigure: t('program:condensedStep.configure'),
  programCondensedStepSpend: t('program:condensedStep.spend'),
  automationCondensedStepConfigure: t('automations:condensedStep.configure'),
  automationCondensedStepSpend: t('automations:condensedStep.spend'),
  configureMultiLocation: t('program:step.configureMultiLocation'),
  publishMultiLocation: t('program:step.publishMultiLocation'),
  multiLocationStepDefaultValues: ''
  // multiLocationStepDefaultValues: ''

  // TODO: remove unused translations once all the steps are implemented
  // programStepConfigure: t('program:step.configure'),
  // programStepSpend: t('program:step.spend'),
  // programStepSummary: t('program:step.summary'),
  // automationStepGetStarted: t('program:step.getStarted'),
  // automationStepBlueprint: t('program:step.chooseBlueprint'),
  // automationStepRefine: t('program:step.refineContent'),
});

const getStepsByProgramType = (programType: ProgramActionType) => {
  const text = pageText();

  switch (programType) {
    case programActions.edit:
    case programActions.create:
      return [
        {
          component: ProgramConfigure,
          label: text.programCondensedStepConfigure,
          error: false
        },
        {
          component: ProgramPublish,
          label: text.programCondensedStepSpend,
          error: false
        }
      ];
    case programActions.automatedEdit:
    case programActions.automatedCreate:
      return [
        {
          component: ProgramConfigure,
          label: text.automationCondensedStepConfigure,
          error: false
        },
        {
          component: ProgramPublish,
          label: text.automationCondensedStepSpend,
          error: false
        }
      ];
    case programActions.multiLocationEdit:
    case programActions.multiLocationCreate:
      return [
        {
          component: MultiLocationConfigure,
          label: text.configureMultiLocation,
          error: false
        },
        {
          component: MultiLocationPublish,
          label: text.publishMultiLocation,
          error: false
        }
      ];
    default:
      // Logger.error('no steps avaliable unsupported program type');
      return [];
  }
};

const ProgramProvider = ({
  children,
  type,
  blueprints,
  selectedBlueprint,
  openBlueprintsModal,
  handleSelectBlueprint,
  isEditing = false,
  automation
}: ProgramProviderProps) => {
  const architecture = useArchitecture();
  const location = useLocation();
  const features = useFeatures();
  const appSettings = useAppSettings();
  const globalContext = useGlobalContext();
  const params = querystring.parse(location.search);
  const isMultiLocation =
    type === programActions.multiLocationCreate ||
    type === programActions.multiLocationEdit;

  const aiChatContext = useAiChat({
    type: aiChatTypes.copywriter,
    selectedBlueprint
  });

  const [disableNavigationBlocker, setDisableNavigationBlocker] =
    useState(false);

  const [creativeValidationErrors, setCreativeValidationErrors] =
    useState<Record<string, any> | null>(null);
  const [previewDrawerOpen, setIsPreviewDrawerOpen] = useState(true);
  const togglePreviewDrawer = () => {
    setIsPreviewDrawerOpen(!previewDrawerOpen);
  };
  const dynamicFieldFriendlyNamesByFieldName =
    getDynamicFieldFriendlyNamesByFieldName(selectedBlueprint);
  const dynamicFieldDisplayMethodByFieldName =
    getDynamicFieldDisplayMethodByFieldName(selectedBlueprint);

  const architectureHasCatalog = hasCatalog(architecture, selectedBlueprint);
  const isContentSelectable = contentSelectable(
    architecture,
    selectedBlueprint
  );
  const blueprintChannels = getOneOfEachChannelKeys(
    selectedBlueprint?.blueprint?.channels
  );
  const contentColumns = contentColumnsFromArchitecture(architecture);

  const creativeType = getCreativeType(
    selectedBlueprint?.blueprint?.publishers
  );
  const showContentSelector =
    (creativeType[0] !== adCreativeTypes.fbDynamicCreative ||
      isContentSelectable) &&
    architectureHasCatalog;

  const [currentDraftId, setCurrentDraftId] = useState<string | undefined>();

  const [stepRefs, setStepRefs] = useState<RefObject<HTMLDivElement>[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<
    string | undefined
  >();

  const [steps] = useState<StepsType[]>(() => {
    const initSteps = getStepsByProgramType(type);
    setStepRefs(initSteps.map(() => createRef<HTMLDivElement>()));
    return initSteps;
  });

  const [currentStep, setCurrentStep] = useState<number>(0);

  const selectExactStep = (
    step: number,
    trackingData?: Record<string, any>
  ) => {
    Instrumentation.logEvent(
      Instrumentation.Events.SelectProgramStepClicked,
      trackingData
    );

    setCurrentStep(currentStep =>
      step >= 0 && step < steps.length ? step : currentStep
    );
  };

  const allMlpLocations = useQuery(getAllLocations, {
    skip:
      type !== programActions.multiLocationCreate &&
      type !== programActions.multiLocationEdit
  });

  const cloneMlpId = params?.multiLocationProgramId || undefined;
  const cloneChildLocationId = params?.locationId || undefined;
  const isCloneOrder = !isNil(params.clone) && !isNil(params.orderId);

  let isDraft = !isNil(params.draftId);

  const handleProgramSubmit = useSubmitHandlerByType(type, currentDraftId);

  const blueprintHasFacebook = checkBlueprintHasFacebook(
    selectedBlueprint?.inputSections || []
  );

  const isAutomated =
    type === programActions.automatedEdit ||
    type === programActions.automatedCreate;
  const isAutomatedEdit = type === programActions.automatedEdit;

  const contentName =
    architecture?.catalog?.friendlyName ||
    t('programCreate:configure.contentDefaultName');

  const catalogId = architecture?.catalog?.id;
  const catalogCollapseKey = architecture?.catalog?.displayCollapseKey ?? 'id';

  const cloneProgramResponse = useQuery(getOrderToCloneProgram, {
    skip: !isCloneOrder,
    variables: {
      orderId: params.orderId as string
    }
  });

  const cloneParentProgramResults = useQuery(getParentProgramToClone, {
    skip: isNil(cloneMlpId),
    variables: {
      multiLocationProgramId: cloneMlpId as string
    }
  });

  const orderToClone = cloneProgramResponse?.data?.order;
  const mlpParentToClone =
    cloneParentProgramResults?.data?.getMultiLocationProgram;

  const offersChanged =
    isDraft ||
    ((automation || orderToClone) &&
      haveOffersChanged({
        orderOfferId: automation?.offer?.id || orderToClone?.offer?.id,
        product: selectedBlueprint,
        isAutomated
      }));

  const programDraftResponse = useQuery(getProgramDrafts, {
    skip: !isDraft,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 1,
      filter: { programDraftIds: [params.draftId as string] }
    }
  });

  const programDraft =
    programDraftResponse?.data?.getProgramDrafts?.edges?.[0]?.node || undefined;

  // make sure this is the same product as the draft else forget draft
  if (isDraft && selectedBlueprint) {
    if (selectedBlueprint?.id !== programDraft?.product?.id) {
      isDraft = false;
    }
  }

  // we should always be saving to the same draft if we load it
  if (!currentDraftId && isDraft && programDraft) {
    setCurrentDraftId(programDraft.id);
  }

  const draftOrder = isDraft
    ? {
        name: programDraft?.name,
        billingDetails: {
          amount: programDraft?.priceAmount,
          tierId: programDraft?.tierId
        },
        orderItem: {
          variableValues: programDraft?.variableValues
        },
        // eslint-disable-next-line no-underscore-dangle
        ...(programDraft?.__typename === DRAFT_MODELS.AUTOMATION && {
          childOrderDurationDays: programDraft?.childOrderDurationDays,
          childOrderNameTemplate: programDraft?.childOrderNameTemplate
        }),
        // eslint-disable-next-line no-underscore-dangle
        ...(programDraft?.__typename === DRAFT_MODELS.MLP && {
          locations: programDraft?.overrides
        })
      }
    : undefined;

  const orgDefaultFacebookPageGroupResults = useQuery(
    getOrgDefaultFacebookPageGroupQuery,
    {
      skip: !blueprintHasFacebook
      // variables: {
      //   antiCache: +new Date()
      // },
      // fetchPolicy: 'no-cache',
      // notifyOnNetworkStatusChange: true
    }
  );

  const facebookPageSelectionsResults = useQuery(
    getFacebookPageSelectionsQuery,
    {
      skip: !blueprintHasFacebook
      // variables: {
      //   antiCache: +new Date()
      // },
      // fetchPolicy: 'no-cache',
      // notifyOnNetworkStatusChange: true
    }
  );

  const [promoCodeError, setPromoCodeError] = useState<boolean | string | null>(
    null
  );

  // anything we want to wait to load before we show the page add here:
  const firstLoading =
    orgDefaultFacebookPageGroupResults.loading ||
    facebookPageSelectionsResults.loading ||
    cloneProgramResponse?.loading ||
    programDraftResponse?.loading ||
    allMlpLocations.loading ||
    cloneParentProgramResults?.loading;

  if (firstLoading) {
    return <Loading />;
  }

  const purchaseDefaultDurationDays =
    selectedBlueprint?.offers?.find((offer: any) => offer.type === 'purchase')
      ?.purchaseDefaultDurationDays ?? 14;

  let preselectedBusinessObjectIds: string[] = [];
  if (params?.contentIds) {
    preselectedBusinessObjectIds = uniq(
      (params?.contentIds as string).split(',')
    );
  }
  if (
    isDraft &&
    programDraft?.catalogFilter &&
    isEmpty(preselectedBusinessObjectIds)
  ) {
    preselectedBusinessObjectIds = programDraft.catalogFilter?.id?.in || [];
  }

  const preselectedPromoCodes = getPreselectedPromoCodes({
    params,
    features,
    isAutomated
  });

  const preselectedFilters =
    isDraft && programDraft?.catalogFilter
      ? convertFilterJSONtoFiltersArray(programDraft?.catalogFilter)
      : getPreselectedFilters({
          params,
          fieldMetadata: architecture?.catalog?.fieldMetadata || []
        });

  const facebookPageSelections =
    facebookPageSelectionsResults?.data?.getFacebookPageSelections || [];

  // store the pages by pageGroupId or pageId for easier look up later
  const facebookPageSelectionsById: {
    [key: string]: (typeof facebookPageSelections)[0];
  } = facebookPageSelections?.reduce((accum, current) => {
    if (current.type === facebookPageTypes.pageGroup && current.pageGroupId) {
      return { ...accum, [current.pageGroupId]: current };
    }
    if (current.pageId) {
      return { ...accum, [current.pageId]: current };
    }
    return accum;
  }, {});

  // org default will usually be the system page group thats set on /#/admin/settings/facebook
  const getOrgDefaultFacebookPageGroup =
    orgDefaultFacebookPageGroupResults?.data?.getOrgDefaultFacebookPageGroup;
  const orgDefaultFacebookPage = getOrgDefaultFacebookPageGroup
    ? facebookPageSelectionsById?.[getOrgDefaultFacebookPageGroup?.id] // Add index signature
    : undefined;

  const isLeadFormFacebookBlueprint =
    selectedBlueprint?.isLeadFormFacebookBlueprint;

  // just set a page so we have a default 99.9% of the time we will have an org default
  // make sure page has tos accepted if the BP contains Facebook lead form
  const eligibleLinkedFacebookPage = isLeadFormFacebookBlueprint
    ? find(facebookPageSelections, { hasAcceptedTos: true })
    : facebookPageSelections?.[0];

  const defaultFacebookPage = getDefaultFacebookPage({
    orgDefaultFacebookPage,
    eligibleLinkedFacebookPage,
    facebookPageSelections,
    globalContext,
    isLeadFormFacebookBlueprint
  });

  const facebook = {
    facebookPages: facebookPageSelections,
    defaultFacebookPage,
    pageSelectionsById: facebookPageSelectionsById,
    getSelectedFacebookPage: ({
      pageGroupId,
      pageId
    }: {
      pageGroupId: string;
      pageId: string;
    }) => {
      return (
        facebookPageSelectionsById?.[pageGroupId] ||
        facebookPageSelectionsById?.[pageId]
      );
    },
    orgDefaultPage: orgDefaultFacebookPage
  };

  const hasFacebookPage =
    (facebook?.defaultFacebookPage &&
      facebook?.defaultFacebookPage?.pageGroupId) ||
    (facebook?.defaultFacebookPage?.pageId &&
      facebook?.defaultFacebookPage?.instagramId);

  const facebookPagesWithNoTos = facebook?.facebookPages?.filter(
    page => !page.hasAcceptedTos
  );

  /*
   * Show the facebook field if:
   *  - A default facebook page has been determined to exist.
   *  - If no eligible default facebook page exists, the BP contains Facebook lead form
   *    and facebook pages have been linked without an accepted ToS.
   */
  const showFacebookField = !!(
    blueprintHasFacebook &&
    (hasFacebookPage ||
      (!hasFacebookPage &&
        facebookPagesWithNoTos?.length &&
        selectedBlueprint?.isLeadFormFacebookBlueprint))
  );

  const isAutomationCreate = type === programActions.automatedCreate;
  const fieldMetadata = architecture?.catalog
    ?.fieldMetadata as ContentSetFieldMetadata[];

  const automationEditFilters = convertFilterJSONtoFiltersArray(
    automation?.catalogFilter
  );

  const startDate = dayjs().startOf('day').toISOString();
  const getEndDate = (appSettings: any, defaultDurationDays: number) => {
    const deactivationDate = getDeactivationDateFromTheme(appSettings);
    if (deactivationDate) {
      // we want to exclude the deactivation date itself from the date picker
      return dayjs(deactivationDate).subtract(1, 'days').toISOString();
    }
    return dayjs(startDate).add(defaultDurationDays, 'd').toISOString();
  };

  // Initial Values
  const initialValues = {
    configureStep: getInitialConfigureStepValues({
      isAutomationCreate,
      isAutomationEdit: isAutomatedEdit,
      filters: isAutomatedEdit ? automationEditFilters : preselectedFilters,
      fieldMetadata,
      preselectedBusinessObjectIds,
      contentName,
      automation,
      isDraft,
      draftOrder
    }),
    spendStep: getInitialSpendStepValues({
      startDate,
      endDate: getEndDate(appSettings, purchaseDefaultDurationDays),
      selectedBlueprint,
      isAutomated,
      architecture,
      preselectedPromoCodes,
      automation,
      orderToClone: orderToClone || draftOrder,
      offersChanged,
      isDraft
    }),
    dynamicUserInputs: getInitialDynamicUserInputs({
      selectedBlueprint,
      defaultFacebookPage,
      automation,
      orderToClone: orderToClone || draftOrder
    }),
    locationsOverrideById:
      isDraft &&
      // eslint-disable-next-line no-underscore-dangle
      programDraft?.__typename === DRAFT_MODELS.MLP &&
      programDraft?.overrides
        ? getDraftOverrrides(programDraft?.overrides)
        : getInitialLocationOverrideById({
            mlpParentToClone,
            cloneChildLocationId,
            startDate,
            endDate: getEndDate(appSettings, purchaseDefaultDurationDays),
            offersChanged,
            selectedBlueprint,
            architecture,
            isAutomated,
            isDraft
          }),
    selectedLocations: getInitialLocationIds({
      mlpParentToClone,
      cloneChildLocationId,
      // eslint-disable-next-line no-underscore-dangle
      ...(programDraft?.__typename === DRAFT_MODELS.MLP && {
        overrideIds: programDraft?.overrides?.map(l => l.locationId)
      })
    }),
    ...(isAutomatedEdit ? { editId: automation?.id } : {}),
    ...(selectedBlueprint?.isCoMarketing
      ? {
          [COMARKETING_FORM_NAME]: guestFormInitialValues
        }
      : {})
  };

  return (
    <ProgramContext.Provider
      key={`program-provider-${type}-${isEditing}-${selectedBlueprint?.id || 'no-blueprint'}`}
      value={{
        // type data
        type,
        isEditing,
        isAutomated,
        isAutomatedEdit,
        isMultiLocation,
        isDraft,
        isCoMarketing: selectedBlueprint?.isCoMarketing,
        currentDraftId,
        setCurrentDraftId,
        // blueprint data
        blueprints,
        selectedBlueprint,
        openBlueprintsModal,
        handleSelectBlueprint,
        blueprintChannels,
        // dynamic fields
        dynamicFieldFriendlyNamesByFieldName,
        dynamicFieldDisplayMethodByFieldName,
        // offers data
        offersChanged,
        // facebook data
        facebook,
        blueprintHasFacebook,
        hasFacebookPage,
        showFacebookField,
        // content / catalog data
        catalogId,
        catalogCollapseKey,
        contentName,
        preselectedBusinessObjectIds,
        hasCatalog: architectureHasCatalog,
        isContentSelectable,
        contentColumns,
        showContentSelector,
        // form data
        initialValues,
        formName: PROGRAM_FORM_NAME,
        // stepper data
        programStepper: {
          currentStep,
          steps,
          stepRefs,
          selectExactStep
        },
        // error states
        promoCodeError,
        setPromoCodeError,
        creativeValidationErrors,
        setCreativeValidationErrors,
        // Which ad preview to display, only add to context when programType is MLP
        selectedLocation,
        setSelectedLocation,
        // preview drawer
        previewDrawerOpen,
        setIsPreviewDrawerOpen,
        togglePreviewDrawer,
        // MLP location query fields
        allMlpLocations,
        handleProgramSubmit,
        // Commonly used data for program form amplitude events
        trackingData: {
          architectureId: selectedBlueprint?.architectureId,
          productId: selectedBlueprint?.id,
          channel: blueprintChannels
        },
        // AI Chat Evolve Copywriter
        aiChatContext,
        // Navigation blocker
        disableNavigationBlocker,
        setDisableNavigationBlocker
      }}
    >
      {children}
    </ProgramContext.Provider>
  );
};

export default ProgramProvider;
