import { isEmpty, isNil } from 'lodash';
import { useWatch } from 'react-hook-form';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Box, Typography, Divider, Grid } from '@mui/material';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useTheme } from '@mui/material/styles';

import { formatPriceWithCents } from 'src/common/numbers';
import useProgram from 'src/pages/Program/utils/useProgram';

import usePromoCode from 'src/pages/Program/ProgramSteps/PriceSummary/usePromoCode';
import {
  PROGRAM_FORM_SECTION_SPEND_NAME,
  scheduleTypes
} from 'src/pages/Program/Constants';
import { useFeatures } from 'src/components/Feature';
import EditableField from 'src/components/EditableField';
import RenderPromoCode from 'src/components/ReduxForm/RenderPromoCode';
import { HelpTip } from 'src/components/Icons';

import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';
import {
  LOCATIONS_OVERRIDES_BY_ID_NAME,
  SELECTED_LOCATIONS_FIELD_NAME
} from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';
import {
  getSpendAmount,
  calcMLPTotal
} from 'src/pages/Program/ProgramSteps/PriceSummary/helpers';

import PromoCodeList from './PromoCodeList';
import { PROMO_SUMMARY_VARIANT } from './constants';

const pageText = () => {
  return {
    subtotal: t('programCreate:priceSummary.subTotal'),
    total: t('programCreate:priceSummary.total'),
    totalAutomated: t('programCreate:priceSummary.totalAutomated'),
    totalAutomatedTip: t('programCreate:priceSummary.totalAutomatedTip'),
    promoCodeFieldLabel: t('programCreate:priceSummary.promoCodeFieldLabel'),
    totalProgramBudget: t('programCreate:priceSummary.totalProgramBudget'),
    coMarketingShare: t('programCreate:priceSummary.coMarketingShare'),
    totalCoMarketing: t('programCreate:priceSummary.totalCoMarketing')
  };
};

const PriceSummary = ({
  selectedBlueprint,
  isAutomated,
  formSectionName = PROGRAM_FORM_SECTION_SPEND_NAME,
  isCoMarketing
}) => {
  const theme = useTheme();
  const text = pageText();
  const features = useFeatures();
  const { isMultiLocation } = useProgram();

  const locationOverridesByID = useWatch({
    name: LOCATIONS_OVERRIDES_BY_ID_NAME,
    defaultValue: {}
  });

  const selectedLocations = useWatch({
    name: SELECTED_LOCATIONS_FIELD_NAME,
    defaultValue: []
  });

  const promoCodes = useWatch({
    name: 'spendStep.promoCodes',
    defaultValue: []
  });

  const billingMethod = useWatch({
    name: 'spendStep.billingMethod',
    defaultValue: ''
  });

  const scheduleType = useWatch({
    name: 'spendStep.scheduleType',
    defaultValue: ''
  });

  const oneTimeSpend = useWatch({
    name: 'spendStep.oneTimeSpend',
    defaultValue: ''
  });
  const subscriptionSpend = useWatch({
    name: 'spendStep.subscriptionSpend',
    defaultValue: ''
  });
  const subscription = useWatch({
    name: 'spendStep.subscription',
    defaultValue: ''
  });

  const isSubscription = scheduleType === scheduleTypes.subscription.value;

  const spendAmount = getSpendAmount({
    oneTimeSpend,
    subscriptionSpend,
    subscription,
    isSubscription,
    billingMethod,
    selectedBlueprint
  });

  const hasProgramPromoCodes = !!features?.promoCodes && !isAutomated;
  const hasAutomationPromoCodes =
    !!features?.automationPromoCodes && isAutomated;

  // no promo codes for MLP/comarketing at this time
  const allowPromoCodes =
    !isMultiLocation &&
    !isCoMarketing &&
    (hasProgramPromoCodes || hasAutomationPromoCodes);

  const hasPromoCodes = allowPromoCodes && !isEmpty(promoCodes);
  const amountAfterDiscount = promoCodes?.[0]?.amountAfterDiscount; // we only allow one promo currently

  const { validatePromoCode, removePromo, promoCodeError } = usePromoCode({
    spendAmount,
    isAutomated,
    promoCodes
  });

  const sharedPromoProps = {
    name: 'promoCodes',
    component: RenderPromoCode,
    formNamespace: formSectionName,
    extraProps: {
      selectedBlueprint,
      promoCodeError,
      spendAmount,
      validatePromoCode
    }
  };

  return (
    <Box>
      {allowPromoCodes && (
        <>
          <Box sx={{ px: 3 }}>
            <EditableField
              labelComponent={
                <Typography>{text.promoCodeFieldLabel}</Typography>
              }
              canEdit={!!promoCodes?.length}
            >
              <HookFormWrapper {...sharedPromoProps} />
            </EditableField>
          </Box>
          <Divider sx={{ my: 3 }} />
        </>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, pl: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {hasPromoCodes && (
            <Grid container columnSpacing={2}>
              <Grid item xs={7} sm={10}>
                <Typography variant={PROMO_SUMMARY_VARIANT}>
                  {text.subtotal}
                </Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sm={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%'
                }}
              >
                <Typography variant={PROMO_SUMMARY_VARIANT}>
                  {formatPriceWithCents(spendAmount)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {hasPromoCodes && (
            <PromoCodeList
              promoCodes={promoCodes}
              removePromo={removePromo}
              spendAmount={spendAmount}
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr max-content',
            columnGap: 2,
            rowGap: 1,
            alignItems: 'center'
          }}
        >
          {isCoMarketing && (
            <>
              <Typography
                variant={PROMO_SUMMARY_VARIANT}
                sx={{ textAlign: 'right' }}
              >
                {text.totalProgramBudget}
              </Typography>
              <Typography
                variant={PROMO_SUMMARY_VARIANT}
                sx={{ justifySelf: 'end' }}
              >
                {formatPriceWithCents(
                  hasPromoCodes && !isNil(amountAfterDiscount)
                    ? amountAfterDiscount
                    : spendAmount
                )}
              </Typography>

              <Typography
                variant={PROMO_SUMMARY_VARIANT}
                sx={{
                  textAlign: 'right',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <HandshakeIcon sx={{ mr: 1 }} /> {text.coMarketingShare}
              </Typography>
              <Typography
                variant={PROMO_SUMMARY_VARIANT}
                sx={{ justifySelf: 'end' }}
              >
                {formatPriceWithCents(
                  (hasPromoCodes && !isNil(amountAfterDiscount)
                    ? amountAfterDiscount
                    : spendAmount) / 2
                )}
              </Typography>
              <Divider
                sx={{
                  gridColumn: 'span 2',
                  width: '100%',
                  my: 1
                }}
              />
            </>
          )}

          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
              ...(isAutomated || isCoMarketing
                ? {
                    alignItems: 'center',
                    display: 'flex'
                  }
                : {})
            }}
          >
            {text.total}

            {isCoMarketing && (
              <span>
                <span style={{ margin: `0 ${theme.spacing()}` }}>
                  {text.totalCoMarketing}
                </span>
              </span>
            )}

            {isAutomated && (
              <span
                style={{
                  alignItems: 'center',
                  color: theme.palette.grey[500],
                  display: 'flex',
                  fontSize: '16px',
                  fontWeight: 'normal'
                }}
              >
                <span style={{ margin: `0 ${theme.spacing()}` }}>
                  {text.totalAutomated}
                </span>
                <HelpTip tipText={text.totalAutomatedTip} />
              </span>
            )}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              justifySelf: 'end'
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 500, color: 'GrayText' }}
            >
              USD
            </Typography>
            <Typography
              data-cy="total-price"
              variant="h4"
              sx={{ fontWeight: 800 }}
            >
              {isMultiLocation &&
                formatPriceWithCents(
                  calcMLPTotal({
                    oneTimeSpend,
                    subscriptionSpend,
                    subscription,
                    numLocations: selectedLocations.length,
                    locationOverridesByID,
                    isSubscription,
                    billingMethod,
                    selectedBlueprint
                  }) * (isCoMarketing ? 0.5 : 1)
                )}
              {!isMultiLocation &&
                formatPriceWithCents(
                  (hasPromoCodes && !isNil(amountAfterDiscount)
                    ? amountAfterDiscount
                    : spendAmount) * (isCoMarketing ? 0.5 : 1)
                )}
              {isSubscription && (
                <Box component="span" sx={{ fontWeight: 'normal' }}>
                  /
                  <Box component="span" sx={{ fontSize: '20px' }}>
                    {t('programCreate:priceSummary.perMonth')}
                  </Box>
                </Box>
              )}
            </Typography>
          </Box>

          {isMultiLocation && (
            <Typography
              variant="body2"
              sx={{
                textAlign: 'right',
                pt: 1,
                gridColumn: '1 / span 2'
              }}
            >
              <Trans
                i18nKey="programCreate:priceSummary.mlpPriceDisclaimer1"
                values={{ num: selectedLocations.length }}
              />
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PriceSummary;
