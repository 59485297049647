import React from 'react';
import { useSuprSendClient, PreferenceOptions } from '@suprsend/react';
import {
  FormControl,
  // FormLabel,
  Switch,
  // Checkbox,
  Box,
  Typography
} from '@mui/material';
import { camelCase } from 'lodash';
import { t } from 'i18next';

import { /* Channel, */ Subcategory } from './NotificationPreferencesSupr';

type NotificationCategoryPreferencesProps = {
  preferenceData: {
    sections: {
      name: string;
      description: string;
      subcategories: Subcategory[];
    }[];
  };
  setPreferenceData: (data: any) => void;
  handleError: () => void;
};

const NotificationCategoryPreferences = ({
  preferenceData,
  setPreferenceData,
  handleError
}: NotificationCategoryPreferencesProps) => {
  const suprSendClient = useSuprSendClient();

  const handleCategoryPreferenceChange = async ({
    data,
    subcategory,
    setPreferenceData
  }: {
    data: boolean;
    subcategory: Subcategory;
    setPreferenceData: (data: any) => void;
  }) => {
    const resp = await suprSendClient.user.preferences.updateCategoryPreference(
      subcategory.category,
      data ? PreferenceOptions.OPT_IN : PreferenceOptions.OPT_OUT
    );
    if (resp.status === 'error') {
      handleError();
    } else {
      setPreferenceData({ ...resp.body });
    }
  };

  // const handleChannelPreferenceInCategoryChange = async ({
  //   channel,
  //   subcategory,
  //   setPreferenceData
  // }: {
  //   channel: Channel;
  //   subcategory: Subcategory;
  //   setPreferenceData: (data: any) => void;
  // }) => {
  //   if (!channel.is_editable) return;

  //   const resp =
  //     await suprSendClient.user.preferences.updateChannelPreferenceInCategory(
  //       channel.channel,
  //       channel.preference === PreferenceOptions.OPT_IN
  //         ? PreferenceOptions.OPT_OUT
  //         : PreferenceOptions.OPT_IN,
  //       subcategory.category
  //     );
  //   if (resp.status === 'error') {
  //     handleError();
  //   } else {
  //     setPreferenceData({ ...resp.body });
  //   }
  // };

  if (!preferenceData.sections) {
    return (
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        {t('notifications:suprsend.empty')}
      </Typography>
    );
  }

  return (
    <Box>
      {preferenceData.sections?.map(section => {
        return (
          <Box sx={{ marginBottom: 2 }} key={`${camelCase(section?.name)}`}>
            {section?.name && (
              <Box>
                <Typography variant="h6">{section?.name}</Typography>
                <Typography variant="body1">{section?.description}</Typography>
              </Box>
            )}

            {section?.subcategories?.map(subcategory => {
              return (
                <Box
                  key={`${camelCase(subcategory?.name)}`}
                  sx={{
                    borderBottom: '1px solid #D9D9D9',
                    paddingBottom: 2,
                    paddingLeft: 2,
                    marginTop: 2
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Box>
                      <Typography variant="body1" sx={{ fontWeight: 600 }}>
                        {subcategory.name}
                      </Typography>

                      <Typography variant="body1">
                        {subcategory.description}
                      </Typography>
                    </Box>
                    <FormControl>
                      {/* <FormLabel id={`all- ${subcategory.category}`}>All</FormLabel> */}
                      <Switch
                        disabled={!subcategory.is_editable}
                        onChange={data => {
                          handleCategoryPreferenceChange({
                            data: data.target.checked,
                            subcategory,
                            setPreferenceData
                          }).catch(() => {});
                        }}
                        checked={
                          subcategory.preference === PreferenceOptions.OPT_IN
                        }
                      />
                    </FormControl>
                  </Box>

                  {/* <Box sx={{ display: 'flex', gap: '10px', marginTop: 2 }}>
                    {subcategory?.channels?.map(channel => {
                      return (
                        <FormControl>
                          <FormLabel id={`all- ${channel.channel}`}>
                            All
                          </FormLabel>
                          <Checkbox
                            key={`${camelCase(channel?.channel)}`}
                            value={channel.preference}
                            title={channel.channel}
                            disabled={!channel.is_editable}
                            onClick={() => {
                              handleChannelPreferenceInCategoryChange({
                                channel,
                                subcategory,
                                setPreferenceData
                              }).catch(() => {});
                            }}
                          />
                        </FormControl>
                      );
                    })}
                  </Box> */}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
export default NotificationCategoryPreferences;
