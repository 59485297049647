export const COMARKETING_FORM_NAME = 'coMarketing';

export const GUEST_PROFILE_TYPES = {
  MORTGAGE_LOAN_OFFICER: 'mortgage_loan_officer',
  REAL_ESTATE_AGENT: 'real_estate_agent'
} as const;

export const guestFormFields = {
  name: 'name',
  email: 'email',
  message: 'message',
  hostGuestAssociationId: 'hostGuestAssociationId',
  selectedGuest: 'selectedGuest',
  inviteExisting: 'inviteExisting'
} as const;

export const allGuestFormFields = [
  guestFormFields.name,
  guestFormFields.email,
  guestFormFields.message,
  guestFormFields.hostGuestAssociationId,
  guestFormFields.selectedGuest,
  guestFormFields.inviteExisting
];

export const INVITE_STATUS = {
  Accepted: 'accepted',
  Declined: 'declined',
  Expired: 'expired',
  Pending: 'pending',
  Revoked: 'revoked'
};

export const guestFormInitialValues = allGuestFormFields.reduce(
  (acc, field) => {
    acc[field] = undefined;
    return acc;
  },
  {} as Record<keyof typeof guestFormFields, undefined>
);
