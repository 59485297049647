import { useState } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import {
  Box,
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack
} from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';

import { Trans } from 'react-i18next';
import Modal from 'src/components/Modal';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { deactivateSupervisorOrder } from './mutations';

const StopAutomationButton = ({
  isActive,
  id,
  refetch,
  deactivateSupervisorOrder
}) => {
  const [deactivating, setDeactivating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleStopProgram = async () => {
    setDeleting(true);
    try {
      await deactivateSupervisorOrder({
        variables: {
          supervisorOrderId: id
        }
      });

      enqueueSnackbar(t('automatedDetails:delete.Snacksuccess'), {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar(t('automatedDetails:delete.snackError'), {
        variant: 'error'
      });
    }

    setDeactivating(false);
    setDeleting(false);
    refetch();
  };

  return (
    <Box>
      {isActive && (
        <IconButton
          onClick={() => {
            setDeactivating(true);
          }}
          variant="contained"
          color="primary"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <CancelIcon fontSize="small" />
          <Typography component="span" variant="body2">
            {t('automatedDetails:stopProgram')}
          </Typography>
        </IconButton>
      )}
      {deactivating && (
        <Modal
          maxWidth="sm"
          onClose={() => setDeactivating(false)}
          open={deactivating}
          headerText={t('automatedDetails:delete.header')}
          FooterComponent={() => (
            <>
              <Button onClick={() => setDeactivating(false)}>
                <Trans i18nKey="automatedDetails:delete.cancel">Cancel</Trans>
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={deleting}
                onClick={handleStopProgram}
              >
                <Trans i18nKey="automatedDetails:delete.confirmButton">
                  Deactivate
                </Trans>
              </Button>
            </>
          )}
        >
          <List sx={{ listStyleType: 'disc', pl: 4 }}>
            <ListItem sx={{ display: 'list-item', py: 0 }}>
              <ListItemText
                primary={t('automatedDetails:delete.cannotBeUndoneBullet')}
              />
            </ListItem>
            <ListItem sx={{ display: 'list-item', py: 0 }}>
              <ListItemText
                primary={t('automatedDetails:delete.existingProgramsBullet')}
              />
            </ListItem>
          </List>
          <Stack sx={{ py: 2 }}>
            {t('automatedDetails:delete.areYouSure')}
          </Stack>
        </Modal>
      )}
    </Box>
  );
};

export default flow(
  graphql(deactivateSupervisorOrder, {
    name: 'deactivateSupervisorOrder'
  })
)(StopAutomationButton);
