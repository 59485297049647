import { useEffect } from 'react';
import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'qs';

import {
  AppBar,
  IconButton,
  LinearProgress,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AppSettingsContextValue, withAppSettings } from 'src/AppSettings';

import MenuIcon from '@mui/icons-material/Menu';

import { History, Location } from 'history';
import { Theme } from '@mui/system';
import { MuiThemeTransitions } from 'src/AppThemeWrapper';
import { FlexExpander } from 'src/components/Styling/FlexExpander';
import { useFeatures } from 'src/components/Feature';
import { useAppState } from 'src/AppStateProvider';
import { NotificationIconButton } from 'src/components/Notification';
import { useGlobalContext } from 'src/GlobalContextProvider';

import { DRAWER_OPEN } from './constants';
import AccountMenu from './AccountMenu';
import SupportMenu from './SupportMenu';

interface ChromeAppBarProps {
  history: History;
  loading: boolean;
  location: Location;
  appSettings: AppSettingsContextValue;
}

const ChromeAppBar = ({
  history,
  loading,
  location: { pathname, search },
  appSettings
}: ChromeAppBarProps) => {
  const theme = useTheme<Theme & MuiThemeTransitions>();

  const upToSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const eqSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const { sideNavOpen, toggleSideNav } = useAppState();

  const { showNotificationPanel } = useFeatures();
  const { me } = useGlobalContext();

  const isNotificationButtonEnabled =
    showNotificationPanel && !!me?.suprsendSubscriberDetails?.userToken;

  useEffect(() => {
    const drawerPrefOpen = localStorage.getItem(DRAWER_OPEN);

    // hide on small by default
    if (upToSmall && sideNavOpen && drawerPrefOpen === null) {
      // pass 'sm' so we don't save pref on small and the click handler passes event
      toggleSideNav(true);
      return;
    }

    // show on >sm by default if user is resizing its window
    if (!upToSmall && !sideNavOpen && drawerPrefOpen === null) {
      toggleSideNav(true);
      return;
    }

    // if query parameter set it overrides
    const currentQueryParams = parse(search.substr(1)) || {};
    const sideNav = currentQueryParams?.sideNav || false;

    if (sideNav) {
      if (
        (sideNav === 'closed' && sideNavOpen) ||
        (sideNav === 'open' && !sideNavOpen)
      ) {
        toggleSideNav(true);
      }

      // Remove the sideNav param.
      delete currentQueryParams.sideNav;
      history.push({
        pathname,
        search: stringify(currentQueryParams)
      });
      return;
    }

    if (drawerPrefOpen === null || eqSmall) {
      // no preference set and we don't keep preference for small
      // this would be a place to set defaults from a theme if we have them
      return;
    }

    // we convert bool to str because localstorage only stores strings
    if (drawerPrefOpen !== sideNavOpen.toString()) {
      toggleSideNav();
    }
  }, [upToSmall]);

  return (
    <AppBar
      sx={{
        marginLeft: sideNavOpen ? `${theme.evSizes.navigationWidth}px` : 0,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
          }),
        width: sideNavOpen
          ? `calc(100% - ${theme.evSizes.navigationWidth}px)`
          : '100%'
      }}
      color="default"
      position="fixed"
      data-cy="chrome-app-bar"
    >
      <Toolbar>
        <IconButton
          aria-label="Menu"
          color="inherit"
          onClick={() => toggleSideNav()}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <FlexExpander />
        {isNotificationButtonEnabled && <NotificationIconButton />}
        {appSettings?.app?.support?.enableAppBar && (
          <SupportMenu loading={loading} />
        )}
        <AccountMenu loading={loading} />
      </Toolbar>
      {loading ? (
        <LinearProgress />
      ) : (
        <LinearProgress variant="determinate" value={100} />
      )}
    </AppBar>
  );
};

export default flow(withRouter, withAppSettings)(ChromeAppBar);
