import { useQuery } from '@apollo/client';
import { Box, Typography, TextField, Autocomplete } from '@mui/material';
import { UserProfileType } from 'src/generated/gql/graphql';
import { Controller, useFormContext } from 'react-hook-form';
import Loading from 'src/components/Loading';
import { t } from 'i18next';
import {
  COMARKETING_FORM_NAME,
  guestFormFields,
  GUEST_PROFILE_TYPES
} from './constants';
import { getGuests } from './queries';

const GuestExistingSelector = () => {
  const { control, setValue } = useFormContext();
  const { data: allGuests, loading } = useQuery(getGuests, {
    variables: {
      filter: {
        profileType: GUEST_PROFILE_TYPES.REAL_ESTATE_AGENT as UserProfileType
      }
    }
  });

  if (loading) return <Loading />;

  const guests = allGuests?.guests || [];
  // test guests
  // .concat(
  //   {
  //     hostGuestAssociationId: '1',
  //     name: 'John Doe',
  //     email: 'john.doe@example.com',
  //     inviteStatus: INVITE_STATUS.Pending as InviteStatus
  //   },
  //   {
  //     hostGuestAssociationId: '2',
  //     name: 'Jane Smith',
  //     email: 'jane.smith@example.com',
  //     inviteStatus: INVITE_STATUS.Pending as InviteStatus
  //   }
  // );

  return (
    <Box sx={{ mb: 2 }}>
      <Controller
        name={`${COMARKETING_FORM_NAME}.${guestFormFields.selectedGuest}`}
        control={control}
        rules={{ required: 'This field is required' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            options={guests || []}
            getOptionLabel={option => option.name}
            renderInput={params => (
              <TextField
                {...params}
                label={t('guest:invite.selector.label')}
                error={!!error}
                helperText={error?.message}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ display: 'flex', alignItems: 'center' }}
                {...props}
              >
                {/* TODO: Add avatar:
                 <Avatar
                  src={option.image}
                  alt={option.name}
                  sx={{ width: 32, height: 32, mr: 2 }}
                /> 
                */}
                <Box>
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {option.email}
                  </Typography>
                </Box>
              </Box>
            )}
            onChange={(_, value) => {
              // Update the form values when a guest is selected
              if (value) {
                // Set the selected guest
                field.onChange(value);
                setValue(`${COMARKETING_FORM_NAME}.name`, value.name);
                setValue(`${COMARKETING_FORM_NAME}.email`, value.email);
                setValue(
                  `${COMARKETING_FORM_NAME}.hostGuestAssociationId`,
                  value.hostGuestAssociationId
                );
              } else {
                // Clear the selection
                field.onChange(null);
              }
            }}
            value={field.value}
          />
        )}
      />
    </Box>
  );
};

export default GuestExistingSelector;
