import { ReactElement } from 'react';
import { useFeatures } from 'src/components/Feature';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { SuprSendProvider } from '@suprsend/react';

export interface NotificationGlobalWrapperProps {
  children: ReactElement;
}

/**
 * Wraps a top part of our app to allow for connection to our notification
 * store (SuprSend)
 */
export const NotificationGlobalWrapper = ({
  children
}: NotificationGlobalWrapperProps) => {
  const { showNotificationPanel } = useFeatures();
  const { loading, me, isAllowListPath } = useGlobalContext();
  const publicApiKey =
    import.meta.env.EVOCALIZE_SUPRSEND_CLIENT_PUBLIC_KEY || '';

  if (!publicApiKey) {
    throw new Error(
      'EVOCALIZE_SUPRSEND_CLIENT_PUBLIC_KEY is not set, update your env'
    );
  }

  if (
    !showNotificationPanel ||
    isAllowListPath ||
    loading ||
    !publicApiKey ||
    !me?.suprsendSubscriberDetails?.userToken
  ) {
    return <>{children}</>;
  }

  return (
    <SuprSendProvider
      publicApiKey={publicApiKey}
      distinctId={me?.id}
      userToken={me?.suprsendSubscriberDetails?.userToken}
    >
      {children}
    </SuprSendProvider>
  );
};
