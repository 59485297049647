import { flow, get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { graphql } from '@apollo/client/react/hoc';
import { isApolloLoading } from 'src/common/apollo';

import { Typography, Paper, IconButton, Box } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import { QuickAutomation as AutomationIcon } from 'src/components/Icons';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/PageTitle';
import { paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';

import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';
import { getAutomation, getArchitectureById } from './queries';

import StopAutomationButton from './StopAutomationButton';
import AutomatedDetails from './AutomatedDetails';

import ProgramsTable from '../Programs/ProgramsTable';

const AutomatedProgramDetails = props => {
  const { architecture, loading, error, automatedProgram, refetch, t } = props;

  const history = useHistory();

  const handleEditNavigation = () => {
    history.push(
      generateLinkPath(paths.architecture.automatedProgramEdit, {
        architectureId: architecture?.id,
        programId: automatedProgram?.id
      })
    );
  };

  if (loading || error) {
    const errorMessage = error ? t('automatedDetails:error.apiError') : null;

    return <Loading error={errorMessage} errorMessage={errorMessage} />;
  }

  const automatedProgramId = get(props, 'match.params.programId');

  const contentFriendlyName = get(props, 'architecture.catalog.friendlyName');

  // Handle the case for when we don't find a program.
  if (!automatedProgram) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <ErrorMessage>
          <Trans
            i18nKey="automatedDetails:messages.notFound"
            values={{ automatedProgramId }}
          />
        </ErrorMessage>
      </Box>
    );
  }

  let subPageTitle = t('automatedDetails:page.title');

  const programName = automatedProgram?.name;

  if (programName) {
    subPageTitle += `: ${programName}`;
  }

  const breadcrumbPieces = [
    {
      text: t('automatedDetails:breadcrumbs.automation'),
      to: paths.automations.base
    },
    {
      text: t('automatedDetails:breadcrumbs.automationDetails')
    }
  ];

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Box>
          <Box>
            <PageTitle subPageTitle={subPageTitle} />
            <BreadcrumbTrail
              sx={theme => ({ marginBottom: theme.spacing(1) })}
              pieces={breadcrumbPieces}
            />
            {programName && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutomationIcon />
                <Typography
                  variant="h4"
                  sx={{ margin: '0 5px', fontWeight: 'bold' }}
                >
                  {programName}
                </Typography>
              </Box>
            )}
          </Box>
          {!automatedProgram?.deactivatedAt && (
            <Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box>
                  <IconButton
                    color="primary"
                    variant="contained"
                    onClick={handleEditNavigation}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 0.5
                    }}
                    disabled={automatedProgram?.deactivatedAt}
                    data-cy="ap-edit-button"
                  >
                    <EditIcon fontSize="small" />
                    <Typography component="span" variant="body2">
                      {t('automatedDetails:edit.button')}
                    </Typography>
                  </IconButton>
                </Box>

                <Box>
                  <StopAutomationButton
                    id={automatedProgramId}
                    refetch={refetch}
                    isActive={!automatedProgram?.deactivatedAt}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Paper sx={{ p: 2, mb: 3 }}>
        <AutomatedDetails
          automatedProgram={automatedProgram}
          contentFriendlyName={contentFriendlyName}
          refetch={refetch}
        />
      </Paper>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5">
          <Trans i18nKey="automatedDetails:headers.programsTitle">
            Generated Programs
          </Trans>
        </Typography>
        <br />
        {automatedProgram?.orders.length ? (
          <ProgramsTable
            supervisorOrderIds={[automatedProgram?.id]}
            showDrafts={false}
          />
        ) : (
          <Typography variant="body1">
            <Trans i18nKey="automatedDetails:table.noPrograms">
              There are no generated programs for this automation
            </Trans>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default flow(
  graphql(getAutomation, {
    name: 'getAutomation',
    options: props => {
      const { match } = props;
      return {
        variables: {
          // architectureId: get(match, 'params.architectureId'),
          supervisorOrderId: get(match, 'params.programId')
        },
        fetchPolicy: 'no-cache'
      };
    },
    props: ({
      getAutomation: { error, refetch, networkStatus, automation }
    }) => {
      const loading = isApolloLoading(networkStatus);
      return {
        loading,
        error,
        refetch,
        automatedProgram: automation
      };
    }
  }),
  graphql(getArchitectureById, {
    name: 'getArchitectureById',
    props: ({ getArchitectureById: { error, loading, architecture } }) => {
      return {
        loading,
        error,
        architecture
      };
    },
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: {
        architectureId: get(props, 'match.params.architectureId')
      }
    })
  }),
  withTranslation(['automatedDetails'])
)(AutomatedProgramDetails);
