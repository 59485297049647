import { useState, useEffect } from 'react';
import { Box /* Typography */ } from '@mui/material';
import {
  useSuprSendClient,
  useAuthenticateUser,
  // SuprSend,
  PreferenceOptions
} from '@suprsend/react';
import Loading from 'src/components/Loading';
import { useSnackbar } from 'notistack';

import NotificationCategoryPreferences from './NotificationCategoryPreferences';
// import ChannelLevelPreferenceItem from './ChannelLevelPreferenceItem';

export type Channel = {
  channel: string;
  is_editable: boolean;
  preference: PreferenceOptions;
};

export type Subcategory = {
  name: string;
  description: string;
  is_editable: boolean;
  preference: PreferenceOptions;
  category: string;
  channels: Channel[];
};

type NotificationPreferences = {
  sections: {
    name: string;
    description: string;
    subcategories: Subcategory[];
  }[];
  channel_preferences: Channel[];
};

// -------------- Main component -------------- //

const NotificationPreferencesSupr = () => {
  const suprSendClient = useSuprSendClient();
  const { authenticatedUser } = useAuthenticateUser();
  const [preferenceData, setPreferenceData] = useState<
    NotificationPreferences | undefined
  >();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = () => {
    enqueueSnackbar('Error updating notification preferences', {
      variant: 'error'
    });
  };

  useEffect(() => {
    if (!authenticatedUser) return;

    suprSendClient.user.preferences
      .getPreferences()
      .then(resp => {
        if (resp.status === 'error') {
          handleError();
        } else {
          setPreferenceData({ ...resp.body });
        }
      })
      .catch(() => {});

    // listen for update in preferences data
    suprSendClient.emitter.on('preferences_updated', (preferenceData: any) => {
      setPreferenceData({ ...preferenceData?.body });
    });

    // listen for errors
    suprSendClient.emitter.on('preferences_error', () => {
      handleError();
    });
  }, [authenticatedUser]);

  if (!preferenceData) return <Loading />;

  return (
    <Box>
      <NotificationCategoryPreferences
        preferenceData={preferenceData}
        setPreferenceData={setPreferenceData}
        handleError={handleError}
      />

      {/* {preferenceData.channel_preferences &&
        preferenceData.channel_preferences.length > 0 && (
          <Box>
            <Typography variant="h6">
              What notifications to allow for channel?
            </Typography>
            <Box>
              <div>
                {preferenceData.channel_preferences?.map((channel, index) => {
                  return (
                    <ChannelLevelPreferenceItem
                      key={index}
                      channel={channel}
                      setPreferenceData={setPreferenceData}
                      handleError={handleError}
                    />
                  );
                })}
              </div>
            </Box>
          </Box>
        )} */}
    </Box>
  );
};

export default NotificationPreferencesSupr;
