import { forOwn, isEmpty, isString, find, head, mapKeys } from 'lodash';
import { dayjs } from 'src/common/dates';
import { BILLING_METHODS } from 'src/common/paymentUtils';
import { convertFiltersArrayToJSON } from 'src/components/AutomatedProgramFilter/helpers';
import { scheduleTypes } from 'src/pages/Program/Constants';
import {
  COMARKETING_FORM_NAME,
  guestFormFields
} from 'src/pages/Program/Guest/constants';
import { getProgramNameTranslated } from '../../ProgramName/ProgramNameUtil';

export interface submitCreateProgramDataInterface {
  [any: string]: any;
}

type ExtractMutationOptions = {
  data: submitCreateProgramDataInterface;
  architecture: any;
  selectedBlueprint: any;
  selectedBusinessObjects: any;
  userMetadataFields: any;
  draftId?: string;
};

export interface MutationParamsInterface {
  architectureId?: string; // Add appropriate types for other properties
  offerId?: string;
  timeZoneRegionName?: string;
  catalogId?: string | null;
  catalogGroupKey?: string | null;
  scheduleStartEpochSeconds?: number;
  id?: string;
  tierId?: string;
  childOrderDurationDays?: number;
  paymentAmount?: string;
  paymentMethodId?: string;
  variableValues: Record<string, string | null>;
  name: string;
  childOrderNameTemplate?: string;
  promoCode: string | null; // Specify that promoCode can be string or null
  catalogFilter: Record<string, unknown>;
}

export const clearEmptyStrings = (data: { [key: string]: string | null }) =>
  forOwn(data, (val, key, obj) => {
    if (isString(val) && val.trim() === '') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = null;
    }
  });

export const analyzeGeneratedAiFields = (data: any) => {
  const dynamicInputValues = data?.dynamicUserInputs;
  const generatedAiFieldValues = data?.genAiAnalysis?.dynamicUserInputs;

  const analysis = {
    aiGeneratedText: false,
    aiGeneratedTextModified: false
  };

  if (!generatedAiFieldValues) {
    return analysis;
  }

  Object.keys(generatedAiFieldValues).forEach(field => {
    const inputValue = dynamicInputValues[field];
    const generatedAiValues = generatedAiFieldValues[field];

    if (!inputValue || !generatedAiValues) {
      return;
    }

    const { generatedText, appliedText } = generatedAiValues;
    const hasGenAiTextBeenApplied = !!appliedText;

    // User generated AI text
    // Set aiGeneratedText to true when:
    // - the generated AI text string is equal to the submitted input value
    // - the user applied the generated text to the form field
    // - aiGeneratedText is false (so we don't keep resetting it to true when it's already true)
    if (
      generatedText?.trim() === inputValue?.trim() &&
      hasGenAiTextBeenApplied &&
      !analysis.aiGeneratedText
    ) {
      analysis.aiGeneratedText = true;
    }

    // User generated and modified AI text
    // Set aiGeneratedTextModified to true when:
    // - aiGeneratedTextModified is false
    // - the user applied the generated text to the form field
    // - the generated and submitted input values are not an exact match
    if (
      generatedText?.trim() !== inputValue?.trim() &&
      hasGenAiTextBeenApplied &&
      !analysis.aiGeneratedTextModified
    ) {
      analysis.aiGeneratedTextModified = true;
    }
  });

  return analysis;
};

export const extractProgramMutationParams = ({
  data,
  architecture,
  selectedBlueprint,
  selectedBusinessObjects,
  userMetadataFields,
  draftId
}: ExtractMutationOptions) => {
  const { aiGeneratedText, aiGeneratedTextModified } =
    analyzeGeneratedAiFields(data);

  const catalogId = architecture?.catalog?.id;
  const catalogDefaultGroupKey = architecture?.catalog?.defaultGroupKey || 'id';
  const isSubscription =
    data?.spendStep?.scheduleType === scheduleTypes.subscription.value;
  const isPurchase =
    data?.spendStep?.scheduleType === scheduleTypes.purchase.value;
  const offerId = data?.spendStep?.offerId;
  const isPartnerInvoiced =
    data?.spendStep?.billingMethod === BILLING_METHODS.partnerInvoice;
  const resultsEstimation = data?.spendStep?.resultsEstimation;
  const isCoMarketing = selectedBlueprint?.isCoMarketing;
  const hasAddedCoMarketingFields =
    data?.[COMARKETING_FORM_NAME]?.[guestFormFields.hostGuestAssociationId] ||
    data?.[COMARKETING_FORM_NAME]?.[guestFormFields.email];

  let chosenStripePlanId;
  if (isSubscription) {
    chosenStripePlanId = data?.spendStep?.subscription;
  }

  let endDate = null;

  // Only set the endDate for non-subscription orders. Otherwise, the API
  // will bark at us.
  if (!isSubscription) {
    endDate = dayjs(data?.spendStep?.endDate).unix();
  }

  const programName = data?.spendStep?.programName;

  const businessObjects =
    selectedBusinessObjects?.selectedBusinessObjects || [];

  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;

  const name = getProgramNameTranslated(
    programName,
    selectedBlueprint,
    businessObjects,
    displayNameTemplate,
    userMetadataFields
  );

  const mutationParams = {
    architectureId: architecture.id,
    offerId,
    name,
    variableValues: clearEmptyStrings(data?.dynamicUserInputs || {}),
    validatePaymentMethod: !isPartnerInvoiced,
    paymentAmount: isSubscription
      ? null // Note: we don’t send paymentAmount for subscriptions
      : data?.spendStep?.oneTimeSpend,
    ...(chosenStripePlanId && { chosenStripePlanId }),
    scheduleStartEpochSeconds: dayjs(data?.spendStep?.startDate).unix(),
    timeZoneRegionName: dayjs.tz.guess(),
    ...(isPurchase ? { scheduleEndEpochSeconds: endDate } : {}),
    // only send catalog info if blueprint requires content
    catalogId: selectedBlueprint?.requiresContent ? catalogId : null,
    catalogGroupBy: selectedBlueprint?.requiresContent
      ? catalogDefaultGroupKey
      : null,
    aiGeneratedText,
    aiGeneratedTextModified,
    ...(draftId && { programDraftId: draftId }),
    ...(resultsEstimation && { resultsEstimation }),
    ...(isCoMarketing &&
      hasAddedCoMarketingFields && {
        coMarketInvite: data?.[COMARKETING_FORM_NAME]?.[
          guestFormFields.inviteExisting
        ]
          ? {
              ...(data?.[COMARKETING_FORM_NAME]?.message && {
                message: data?.[COMARKETING_FORM_NAME]?.message
              }),
              hostGuestAssociationId:
                data?.[COMARKETING_FORM_NAME]?.[
                  guestFormFields.hostGuestAssociationId
                ]
            }
          : {
              name: data?.[COMARKETING_FORM_NAME]?.[guestFormFields.name],
              email: data?.[COMARKETING_FORM_NAME]?.[guestFormFields.email]
            }
      })
  };

  // non invoiced case only
  if (!isPartnerInvoiced) {
    mutationParams.paymentMethodId = data?.spendStep?.paymentMethodId;
  }

  const businessObjectSelectorValues =
    data?.configureStep?.businessObjectSelector;

  // We only want to send content filters if there are BOs selected.
  if (!isEmpty(businessObjectSelectorValues)) {
    mutationParams.catalogFilter = {
      id: {
        in: businessObjectSelectorValues
      }
    };
  } else {
    mutationParams.catalogFilter = {};
  }

  return mutationParams;
};

type FormatAutomatedProgramsMutationParams = {
  data: any;
  architecture: any;
  selectedBlueprint: any;
  draftId?: string;
  isEdit: boolean;
};

export const formatAutomatedProgramsMutationParams = ({
  data,
  architecture,
  selectedBlueprint,
  draftId,
  isEdit
}: FormatAutomatedProgramsMutationParams): MutationParamsInterface => {
  const filters = data?.configureStep?.automatedPrograms?.filters || [];

  const catalogId = architecture?.catalog?.id;
  const catalogDefaultGroupKey = architecture?.catalog?.defaultGroupKey || 'id';

  const paymentPlans = selectedBlueprint?.paymentPlans;
  const billingMethod = data?.spendStep?.billingMethod;
  const offerTypeKey =
    data?.spendStep?.scheduleType === scheduleTypes.subscription.value
      ? 'subscriptionOffers'
      : 'purchaseOffers';
  const resultsEstimation = data?.spendStep?.resultsEstimation;

  const currentOffer = find(paymentPlans[offerTypeKey], {
    billingMethod
  });

  const mutationParams = {
    ...(!isEdit
      ? {
          // these params are sent only for create
          architectureId: architecture.id,
          offerId: currentOffer?.id,
          timeZoneRegionName: dayjs.tz.guess(),
          // only send catalog info if blueprint requires content
          catalogId: selectedBlueprint?.requiresContent ? catalogId : null,
          catalogGroupKey: selectedBlueprint?.requiresContent
            ? catalogDefaultGroupKey
            : null,
          scheduleStartEpochSeconds: dayjs(data?.spendStep?.startDate).unix(),
          ...(resultsEstimation && { resultsEstimation })
        }
      : {
          // these params are sent only for edit
          id: data?.editId
        }),
    // send the tierId if the schedule type is subscription
    ...(data?.spendStep?.scheduleType === scheduleTypes.subscription.value && {
      tierId: data?.spendStep?.subscription?.split('tier:')[1]
    }),
    // send these if the schedule type is purchase
    ...(data?.spendStep?.scheduleType === scheduleTypes.purchase.value && {
      childOrderDurationDays: parseInt(data?.spendStep?.scheduleDays, 10),
      paymentAmount: data?.spendStep?.oneTimeSpend
    }),
    ...(draftId && { programDraftId: draftId }),
    paymentMethodId: data?.spendStep?.paymentMethodId,
    variableValues: clearEmptyStrings(data?.dynamicUserInputs || {}),
    name: data?.configureStep?.automatedProgramName || '',
    childOrderNameTemplate: data?.spendStep?.programName,
    promoCode: null as string | null,
    catalogFilter: {},
    ...(resultsEstimation && { resultsEstimation })
  };

  const promoCodes = data?.spendStep?.promoCodes as { [any: string]: string }[];
  if (!isEmpty(promoCodes)) {
    mutationParams.promoCode = head(promoCodes)?.promoCode || null;
  }

  if (!isEmpty(filters)) {
    mutationParams.catalogFilter = convertFiltersArrayToJSON(filters);
  } else {
    mutationParams.catalogFilter = {};
  }

  return mutationParams;
};

type ExtractLocationMutationOptions = {
  data: any;
  isOneTimePurchase: boolean;
  isSubscription: boolean;
};

export const extractLocationMutationSituation = ({
  data,
  isOneTimePurchase,
  isSubscription
}: ExtractLocationMutationOptions) => {
  return data?.selectedLocations?.map((locationId: string) => {
    if (data.locationsOverrideById[locationId]) {
      const location = data.locationsOverrideById[locationId];
      return {
        locationId,
        ...(location.spendStep.oneTimeSpend &&
          isOneTimePurchase && {
            amountOverride: location.spendStep.oneTimeSpend
          }),
        ...(location.dynamicUserInputs && {
          variableValuesOverride: location.dynamicUserInputs
        }),
        ...(location.spendStep.startDate && {
          scheduleStartEpochSecondsOverride: dayjs(
            location.spendStep.startDate
          ).unix()
        }),
        ...(location.spendStep.endDate &&
          isOneTimePurchase && {
            scheduleEndEpochSecondsOverride: dayjs(
              location.spendStep.endDate
            ).unix()
          }),
        ...(location.spendStep.subscription &&
          isSubscription && {
            tierIdOverride: location.spendStep.subscription?.split('tier:')[1]
          }),
        ...(location.spendStep.resultsEstimation && {
          resultsEstimation: location.spendStep.resultsEstimation
        })
      };
    }
    return { locationId };
  });
};

type CreateMlpMutationParamsOptions = {
  mutationParams: MutationParamsInterface;
  data: any;
  isSubscription: boolean;
  isOneTimePurchase: boolean;
};

interface MLPMutationParams
  extends Omit<MutationParamsInterface, 'paymentAmount'> {
  defaultOrderAmount?: string;
  locations: any[];
}

export const createMlpMutationParams = ({
  mutationParams,
  data,
  isSubscription,
  isOneTimePurchase
}: CreateMlpMutationParamsOptions): MLPMutationParams => {
  // a couple order placement names are different for MLP
  const renameMap: { [key: string]: string } = {
    paymentAmount: 'defaultOrderAmount'
  };

  return {
    // rename a couple of the default keys
    ...mapKeys(mutationParams, (value, key) => {
      return renameMap[key] || key;
    }),
    // send the tierId if the schedule type is subscription
    ...(isSubscription && {
      defaultTierId: data?.spendStep?.subscription?.split('tier:')[1]
    }),
    // add our locations and location overrides
    locations: extractLocationMutationSituation({
      data,
      isOneTimePurchase,
      isSubscription
    })
  } as MLPMutationParams;
};
