import { DynamicForm } from 'src/components/ReduxForm';
import {
  ACCOUNT_SELECTION_STRATEGY_DISPLAY_NAME_BY_GOOGLE,
  createGoogleUserListAccountCreationInputs,
  defaultAudienceToolColumnSettings,
  FORM_NAME_CREATE_GOOGLE_USER_LIST_CREATION_RULE
} from 'src/pages/Admin/AudienceTools/constants';
import { Button, Typography } from '@mui/material';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { MutableRefObject, ReactElement } from 'react';
import { flow } from 'lodash';
import { getFormValues, isSubmitting, reduxForm } from 'redux-form';
import {
  GoogleUserListAccountSelectionStrategy,
  UpsertGoogleUserListAccountCreationRuleInput
} from 'src/generated/gql/graphql';
import { connect, DefaultRootState } from 'react-redux';
import DataTable from 'src/components/DataTable';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  deleteGoogleUserListCreationRuleMutation,
  fetchActiveGoogleAccountsMinimalQuery,
  fetchGoogleUserListCreationRulesQuery,
  GoogleUserListCreationRule,
  upsertGoogleUserListCreationRuleMutation
} from 'src/pages/Admin/AudienceTools/Forms/CreateGoogleUserListAccountCreationRule/queries';
import { GridColDef } from '@mui/x-data-grid';
import { GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { GridRowId, GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { sortRowsOnPriority } from 'src/pages/Admin/AudienceTools/Forms/helpers';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import SentryUtil from 'src/common/SentryUtil';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Box } from '@mui/system';

const pageText = () => ({
  genericError: t(
    'audienceTools:googleUserListAccountCreationRule.genericError'
  ),
  createRuleSuccess: t(
    'audienceTools:googleUserListAccountCreationRule.createSuccess'
  ),
  editRuleSuccess: t(
    'audienceTools:googleUserListAccountCreationRule.editSuccess'
  ),
  createRule: t('audienceTools:googleUserListAccountCreationRule.createRule'),
  editRule: t('audienceTools:googleUserListAccountCreationRule.editRule')
});

const columns = (): GridColDef[] => [
  {
    ...defaultAudienceToolColumnSettings,
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    editable: true,
    flex: 1
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'accountSelectionStrategy',
    headerName: t(
      'audienceTools:googleUserListAccountCreationRule.tableHeader.accountSelectionStrategy'
    ),
    valueGetter: ({ row }: GridValueGetterParams<GoogleUserListCreationRule>) =>
      ACCOUNT_SELECTION_STRATEGY_DISPLAY_NAME_BY_GOOGLE()[
        row?.accountSelectionStrategy
      ] || 'Unknown'
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'googleAccount.name',
    headerName: t(
      'audienceTools:googleUserListAccountCreationRule.tableHeader.googleAccountName'
    ),
    valueGetter: ({ row }: GridValueGetterParams<GoogleUserListCreationRule>) =>
      row?.googleAccount?.name
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'group.id',
    headerName: t(
      'audienceTools:googleUserListAccountCreationRule.tableHeader.groupId'
    ),
    valueGetter: ({ row }: GridValueGetterParams<GoogleUserListCreationRule>) =>
      row?.group?.id
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'userMetadata.managerAccountUserMetadataKey',
    headerName: t(
      'audienceTools:googleUserListAccountCreationRule.tableHeader.managerAccountKey'
    ),
    valueGetter: ({ row }: GridValueGetterParams<GoogleUserListCreationRule>) =>
      row?.userMetadata?.managerAccountUserMetadataKey
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'userMetadata.customerAccountUserMetadataKey',
    headerName: t(
      'audienceTools:googleUserListAccountCreationRule.tableHeader.customerAccountKey'
    ),
    valueGetter: ({ row }: GridValueGetterParams<GoogleUserListCreationRule>) =>
      row?.userMetadata?.customerAccountUserMetadataKey
  }
];

interface OrganizationFormValues {
  accountSelectionStrategy: GoogleUserListAccountSelectionStrategy.Organization;
  priority: number;
  googleAccountId: string;
}
interface UserMetadataFormValues {
  accountSelectionStrategy: GoogleUserListAccountSelectionStrategy.UserMetadata;
  priority: number;
  managerAccountKey: string;
  customerAccountKey: string;
}
interface GroupFormValues {
  accountSelectionStrategy: GoogleUserListAccountSelectionStrategy.Group;
  priority: number;
  googleAccountId: string;
  groupId: string;
}

type GoogleUserListCreationRuleFormValues =
  | OrganizationFormValues
  | UserMetadataFormValues
  | GroupFormValues;

function isFormValueInList(
  value: GoogleUserListCreationRuleFormValues | undefined,
  currentData: GoogleUserListCreationRule[] | undefined,
  gridApiRef: MutableRefObject<GridApiPro>
) {
  if (value == null || currentData == null) {
    return false;
  }

  const existingRule = currentData.find(existingRule => {
    switch (value.accountSelectionStrategy) {
      case GoogleUserListAccountSelectionStrategy.Group:
        return (
          existingRule.accountSelectionStrategy ===
            value.accountSelectionStrategy &&
          existingRule.group?.id === value.groupId &&
          existingRule.googleAccount?.id === value.googleAccountId
        );

      case GoogleUserListAccountSelectionStrategy.Organization:
        return (
          existingRule.accountSelectionStrategy ===
            value.accountSelectionStrategy &&
          existingRule.googleAccount?.id === value.googleAccountId
        );

      case GoogleUserListAccountSelectionStrategy.UserMetadata:
        return (
          existingRule.accountSelectionStrategy ===
            value.accountSelectionStrategy &&
          existingRule.userMetadata?.managerAccountUserMetadataKey ===
            value.managerAccountKey &&
          existingRule.userMetadata?.customerAccountUserMetadataKey ===
            value.customerAccountKey
        );

      default:
        return false;
    }
  });

  if (existingRule == null) {
    return false;
  }

  return gridApiRef.current?.getRow(existingRule.id) != null;
}

interface InternalCreateGoogleUserListFormProps
  extends InjectedFormProps<GoogleUserListCreationRuleFormValues> {
  formValues?: GoogleUserListCreationRuleFormValues;
}

const CreateGoogleUserListForm = ({
  handleSubmit,
  dirty,
  invalid,
  submitting,
  formValues,
  reset
}: InternalCreateGoogleUserListFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const text = pageText();

  const { data: googleAccounts } = useQuery(
    fetchActiveGoogleAccountsMinimalQuery
  );

  const [fetchGoogleUserListCreationRules, existingRules] = useLazyQuery(
    fetchGoogleUserListCreationRulesQuery
  );

  const [deleteGoogleUserListCreationRule] = useMutation(
    deleteGoogleUserListCreationRuleMutation
  );

  const [upsertGoogleUserListCreationRule] = useMutation(
    upsertGoogleUserListCreationRuleMutation
  );

  const gridApiRef = useGridApiRef();

  const isEditingExistingRule = isFormValueInList(
    formValues,
    existingRules?.data?.googleUserListAccountCreationRules,
    gridApiRef
  );

  const onSubmit = async (formValues: GoogleUserListCreationRuleFormValues) => {
    let inputPayload: UpsertGoogleUserListAccountCreationRuleInput;
    switch (formValues.accountSelectionStrategy) {
      case GoogleUserListAccountSelectionStrategy.Group:
        inputPayload = {
          accountSelectionStrategy:
            GoogleUserListAccountSelectionStrategy.Group,
          priority: formValues.priority,
          groupId: formValues.groupId,
          internalAccountId: formValues.googleAccountId
        };
        break;
      case GoogleUserListAccountSelectionStrategy.Organization:
        inputPayload = {
          accountSelectionStrategy:
            GoogleUserListAccountSelectionStrategy.Organization,
          priority: formValues.priority,
          internalAccountId: formValues.googleAccountId
        };
        break;
      case GoogleUserListAccountSelectionStrategy.UserMetadata:
        inputPayload = {
          accountSelectionStrategy:
            GoogleUserListAccountSelectionStrategy.UserMetadata,
          priority: formValues.priority,
          userMetadata: {
            managerAccountUserMetadataKey: formValues.managerAccountKey,
            customerAccountUserMetadataKey: formValues.customerAccountKey
          }
        };
        break;
      default:
        return;
    }

    try {
      const result = await upsertGoogleUserListCreationRule({
        variables: inputPayload
      });

      if (
        result.data?.upsertGoogleUserListAccountCreationRule == null ||
        result.errors != null
      ) {
        const message = result.errors?.[0]?.message;
        enqueueSnackbar(message || text.genericError, {
          variant: 'error'
        });
        return;
      }

      enqueueSnackbar(
        isEditingExistingRule ? text.editRuleSuccess : text.createRuleSuccess,
        {
          variant: 'success'
        }
      );

      gridApiRef.current?.updateRows([
        result.data.upsertGoogleUserListAccountCreationRule
      ]);

      reset();
    } catch (ex) {
      SentryUtil.captureException(ex as Error);
      enqueueSnackbar(text.genericError, {
        variant: 'error'
      });
    }
  };

  const loadMoreRows = async (
    _reset: boolean,
    _newRowsLength?: number,
    sortModel?: GridSortModel
  ) => {
    const results = await fetchGoogleUserListCreationRules({
      fetchPolicy: 'no-cache'
    });

    // This endpoint is not paginated and thus never has more rows
    return {
      rows: sortRowsOnPriority(
        sortModel,
        results.data?.googleUserListAccountCreationRules
      ) as GoogleUserListCreationRule[],
      hasMoreRows: false
    };
  };

  const saveRow = async (row: GoogleUserListCreationRule) => {
    const result = await upsertGoogleUserListCreationRule({
      variables: {
        priority: row.priority,
        accountSelectionStrategy: row.accountSelectionStrategy,
        // we are getting __typename in the userMetadata object and it is causing the mutation to fail
        userMetadata: row.userMetadata
          ? {
              managerAccountUserMetadataKey:
                row.userMetadata.managerAccountUserMetadataKey,
              customerAccountUserMetadataKey:
                row.userMetadata.customerAccountUserMetadataKey
            }
          : undefined,
        groupId: row.group?.id,
        internalAccountId: row.googleAccount?.id
      }
    });

    return {
      success: true,
      values: result?.data?.upsertGoogleUserListAccountCreationRule
    };
  };

  const deleteRow = async (id: GridRowId) => {
    await deleteGoogleUserListCreationRule({
      variables: { ruleId: id as string }
    });
    return { success: true };
  };

  return (
    <Box data-cy="create-google-user-list-account-rule-form">
      <Box>
        <Typography
          component="h3"
          sx={theme => ({ marginBottom: theme.spacing(1) })}
        >
          <Trans i18nKey="audienceTools:googleUserListAccountCreationRule.tableTitle">
            Rules
          </Trans>
        </Typography>
        <DataTable
          deleteConfirmationModal
          editFocusField="priority"
          initialState={{
            sorting: {
              sortModel: [{ field: 'priority', sort: 'asc' }]
            }
          }}
          columns={columns()}
          loadMoreRows={loadMoreRows}
          saveRow={saveRow}
          deleteRow={deleteRow}
          customApiRef={gridApiRef}
        />
      </Box>
      <Box>
        <form
          autoComplete="off"
          data-cy="create-google-user-list-account-creation-rule"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={theme => ({
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(2)
            })}
          >
            <Typography component="h3">
              <Trans i18nKey="audienceTools:googleUserListAccountCreationRule.formCreateTitle">
                Create A Rule
              </Trans>
            </Typography>
            {isEditingExistingRule && (
              <Typography sx={{ color: 'warning.main' }}>
                <Trans i18nKey="audienceTools:googleUserListAccountCreationRule.editWarning">
                  You are editing an existing rule
                </Trans>
              </Typography>
            )}
          </Box>
          <DynamicForm
            inputs={createGoogleUserListAccountCreationInputs(
              formValues?.accountSelectionStrategy ||
                GoogleUserListAccountSelectionStrategy.Organization,
              googleAccounts?.googleAccounts
            )}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!dirty || invalid || submitting}
            type="submit"
          >
            {isEditingExistingRule ? text.editRule : text.createRule}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

function mapStateToProps(state: DefaultRootState) {
  const formValues = getFormValues(
    FORM_NAME_CREATE_GOOGLE_USER_LIST_CREATION_RULE
  )(state);
  const submitting = isSubmitting(
    FORM_NAME_CREATE_GOOGLE_USER_LIST_CREATION_RULE
  )(state);

  const initialValues = {
    accountSelectionStrategy:
      GoogleUserListAccountSelectionStrategy.Organization
  };

  return { formValues, initialValues, submitting };
}

export default flow(
  reduxForm({
    form: FORM_NAME_CREATE_GOOGLE_USER_LIST_CREATION_RULE,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps)
)(CreateGoogleUserListForm) as () => ReactElement;
