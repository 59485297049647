import { useMemo } from 'react';
import { isEmpty, mapValues, omit } from 'lodash';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import { diffTwoObjects } from 'src/common/utilities';

import { DEFAULT_NEW_ITEM_ID } from './constants';
import { createHandleDeleteClick } from './helpers';

const DataTableRowActionCell = props => {
  const {
    id,
    onSaveRow,
    onDeleteRow,
    editFocusField,
    setIsLoading,
    setTableIsEditing,
    isTableEditing
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const api = useGridApiContext();

  const hasDeleteMode = useMemo(() => !!onDeleteRow, [onDeleteRow]);
  const hasEditMode = useMemo(() => !!onSaveRow, [onSaveRow]);
  const allColumns = useMemo(() => api.current.getAllColumns(), [api]);
  const rowMode = api.current.getRowMode(id);
  const isInEditMode = rowMode === 'edit';

  const handleEditClick = event => {
    event.stopPropagation();

    // set table to edit mode
    setTableIsEditing(true);

    // set row to edit mode and focus on the first field unless otherwise specified
    api.current.startRowEditMode({
      id,
      fieldToFocus: editFocusField || allColumns[0].field
    });
  };

  const handleSaveClickAsync = async event => {
    event.stopPropagation();
    const unEditedValues = api.current.getRow(id);
    const editedValues = api.current.getRowWithUpdatedValues(id);
    const hasMadeChanges = !isEmpty(
      diffTwoObjects(editedValues, unEditedValues)
    );

    // make sure the row with the default id is no longer in the table
    api.current.updateRows([{ id: DEFAULT_NEW_ITEM_ID, _action: 'delete' }]);

    // If no changes were made, stop here.
    if (!hasMadeChanges) {
      return;
    }

    // Set loading to show the spinner before we call the api.
    setIsLoading(true);

    // If changes were made to the row, set up the request and call the api.
    const extractedValues = omit(
      mapValues(editedValues, ev => ev),
      'isNew'
    );
    const saveResponse = await onSaveRow(extractedValues);

    const values = saveResponse?.values;

    // Success: Update the row with the data returned from the api.
    if (saveResponse?.success && values) {
      api.current.updateRows([values]);

      // Only stop table editing mode if the save was successful.
      setTableIsEditing(false);
      api.current.stopRowEditMode({ id });
    } else {
      // Error: Show a message but leave the row in edit mode so the user can try again.
      enqueueSnackbar(t('dataTable:saveError'), {
        variant: 'error'
      });
    }

    // Turn off loading state.
    setIsLoading(false);
  };

  const handleCancelClick = event => {
    event.stopPropagation();
    const row = api.current.getRowWithUpdatedValues(id);

    // remove the row if was new and not saved
    if (row.isNew) {
      api.current.updateRows([{ id: row?.id, _action: 'delete' }]);
      // stop editing
      api.current.stopRowEditMode({ id });
    } else {
      // stop editing & discard changes
      api.current.stopRowEditMode({ id, ignoreModifications: true });
    }

    // unset table edit mode
    setTableIsEditing(false);
  };

  if (isInEditMode) {
    return (
      <>
        <IconButton
          aria-label="save"
          color="primary"
          onClick={handleSaveClickAsync}
          size="small"
          title={t('dataTable:saveChangesTooltip')}
        >
          <SaveIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="cancel"
          sx={{ color: theme => theme.palette.text.primary }}
          color="inherit"
          onClick={handleCancelClick}
          size="small"
          title={t('dataTable:cancelEditingTooltip')}
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      </>
    );
  }

  return (
    <>
      {hasEditMode && (
        <Tooltip
          title={
            isTableEditing
              ? t('dataTable:alreadyEditingTooltip')
              : t('dataTable:editThisRowTooltip')
          }
        >
          <span>
            <IconButton
              aria-label="edit"
              color="inherit"
              onClick={handleEditClick}
              size="small"
              sx={{ color: theme => theme.palette.text.primary }}
              disabled={isTableEditing}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {hasDeleteMode && (
        <IconButton
          aria-label="delete"
          color="inherit"
          onClick={createHandleDeleteClick({ id, onDeleteRow, api })}
          size="small"
          title={t('dataTable:deleteRowTooltip')}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default DataTableRowActionCell;
