import { gql } from 'src/generated/gql';

export const getGuests = gql(`
  query getGuestsInvite($filter: GuestQueryFilter!) {
    guests(filter: $filter) {
      name
      email
      inviteStatus
      hostGuestAssociationId
      guestProfile
    }
  }
`);
